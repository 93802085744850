<template>
  <BModal
    id="modal-limit-quota"
    ref="modal-limit-quota"
    hide-footer
    hide-header
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="py-[24px]">
      <div class="flex justify-center">
        <img
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          alt="warning"
        >
      </div>
      <div class="mt-[24px] text-center text-[24px] font-semibold text-[#333333]">
        Kuota Pembuatan Kartu Habis
      </div>
      <div class="mt-[16px] text-center text-[#626262]">
        Kuota pembuatan kartu kamu sudah habis. Agar bisa mendapatkan kuota kartu unlimited, pastikan sebagian besar kartu yang kamu buat telah aktif digunakan
      </div>
      <div class="flex justify-center mt-[24px]">
        <BButton
          variant="primary"
          @click="$bvModal.hide('modal-limit-quota')"
        >
          Tutup
        </BButton>
      </div>
    </div>

  </BModal>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
