import { render, staticRenderFns } from "./List.vue?vue&type=template&id=3170253e&scoped=true&"
import script from "./List.vue?vue&type=script&lang=js&"
export * from "./List.vue?vue&type=script&lang=js&"
import style0 from "./Komcard.scss?vue&type=style&index=0&id=3170253e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3170253e",
  null
  
)

export default component.exports